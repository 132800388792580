import { PowerGlitchOptions } from 'powerglitch';

export const MOBILE_WIDTH = 1072;
export const LARGE_SIZE_WIDTH = 1670;
export const MEDIUM_SIZE_WIDTH = 1420;
export const SMALL_WIDTH = 750;
export const EXTRA_SMALL_WIDTH = 500;
export const SMALL_SIZE_HEIGHT = 700;
export const EXTRA_SMALL_SIZE_HEIGHT = 450;
export const MEDIUM_SIZE_HEIGHT = 930;
export const GAME_ACTION_DEFAULT_ELEMENT_WIDTH = 300;
export const NAME_MINIMUM_LENGTH = 3;
export const NAME_MAXIMUM_LENGTH = 40;
export const GAME_ID_LENGTH = 26;
export const MINIMUM_NO_WARNING_LENGTH = 5;
export const IS_PLAYER_TRAY_OPEN_STORAGE_KEY = 'is-player-tray-open';
export const IS_LOG_BLOCK_CLOSED_STORAGE_KEY = 'is-log-block-closed';
export const RELOAD_GAME_PAGE_ON_IDLE_SEC = 60;
export const SHOW_SKIP_ACTION_BUTTON_ON_IDLE_SEC = 90;

export const DEFAULT_THEME_VOLUME_LEVEL = 30;
export const DEFAULT_PLAYERS_VOLUME_LEVEL = 10;
export const MINIMUM_VOLUME_LEVEL = 0;
export const MAXIMUM_VOLUME_LEVEL = 100;
export const VOLUME_CHANGE_STEP = 10;

export const zIndexes = {
  inputFieldPopover: 3001,
  ruleBookDrawer: 2900,
  draggableTableElement: {
    mainBox: 2000,
    draggingIndicator: 4000,
  },
  contentBox: 3,
  titleBox: 3,
  one: 1,
  two: 2,
  playerDrawer: 1500,
  cardDrawer: 1500,
  roomWidgets: 1600,
  tooltipPopper: 5000,
  mobileActionDrawer: 2870,
  mobileHeader: 5,
  movingEssenceAnimation: 2800,
  expandedOtherPlayerHand: 2830,
};

export const glitchStatsContentProperties = {
  createContainers: true,
  hideOverflow: false,
  timing: {
    duration: 2000,
    easing: 'ease-in-out',
  },
  glitchTimeSpan: {
    start: 0.6,
    end: 0.7,
  },
  shake: {
    velocity: 2,
    amplitudeX: 0.2,
    amplitudeY: 0.5,
  },
  slice: {
    count: 4,
    velocity: 12,
    minHeight: 0.01,
    maxHeight: 0.08,
    hueRotate: true,
  },
  pulse: false,
} as PowerGlitchOptions;
